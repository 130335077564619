.take-offline {
  position: fixed;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(12, 12, 12, 0.8);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .checkbox-box {
    min-height: 40px;
  }
  span {
    margin-left: 15px;
    display: inline-block;
    border-bottom: 1px dotted #86868f;
    cursor: pointer;
  }
  .main {
    // background: white;
    // width: 400px;
    margin-top: 5%;
    border: 1px solid #323136;
    background-color: #29272d;
    border-radius: 15px;
    padding: 30px 20px;
    max-width: 500px;
  }
}

.fixed-frame {
  position: fixed;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(12, 12, 12, 0.8);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .main {
    margin-top: 5%;
    border: 1px solid #323136;
    background-color: #29272d;
    border-radius: 15px;
    padding: 30px 20px;
    max-width: 500px;
  }
}

