/* default styles */
html,
body,
#root {
  height: 100%;
  background-color: $root-bg-dark;
}
#root {
  height: auto;
}
* {
  font-family: "Open Sans", sans-serif;
}
a {
  color: $color1;
  &:hover {
    color: darken($color1, 5%);
    text-decoration: none;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  // color: $heading-dark;
  color: $text-white;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  // color: $text-dark;
  color: $text-white;
  padding-top: 45px;
}

input[type="file"] {
  visibility: hidden;
  position: absolute;
}

.page-wrapper {
  background-color: $root-bg-dark;
  min-height: 100%;
}

.bg-box {
  &.overflow-y {
    @media (min-width: 641px) and (max-width: 760px) {
      overflow-y: auto;
    }
  }
  border: 1px solid #323136;
  background-color: $bg-dark;
  border-radius: 15px;
}

hr {
  border-color: $border-dark;
}

.search-group {
  .form-group {
    display: inline-block;
    width: 49%;
    margin-right: 1%;
    margin-bottom: 0.5rem;
    @media (max-width: 991px) {
      display: inline-block;
      width: 32%;
    }
  }
}
.filter-group {
  .form-group {
    display: inline-block;
    width: 49%;
    margin-bottom: 0.5rem;
    margin-right: 1%;
  }
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  &.height-200 {
    height: 200px;
  }
  &.height-400 {
    height: 400px;
  }
}
.progress-parent {
  .progress-number {
    &.no-inline {
      display: block;
      width: 100%;
    }
    display: inline-block;
    width: 10%;
    word-break: keep-all;
  }
  .progress-box {
    &.no-inline {
      display: block;
      width: 100%;
    }
    display: inline-block;
    width: 90%;
  }
}

.progress {
  height: 10px;
  position: relative;
  background-color: $bg-dark3;
  .progress-text {
    position: absolute;
  }
  .progress-bar {
    background-color: $color1;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.border-bottom1 {
  border-bottom: 1px solid $root-bg-dark;
}

.wm-100 {
  @media (max-width: 991px) {
    width: 100%;
  }
}

.offline {
  background: #af2727;
  display: block;
  top: 18px;
  z-index: 99;
  left: 420px;
  padding: 2px 10px;
  color: #1c1a21;
  margin-top: 18px;
  margin-bottom: -20px;
}

.medium,
medium {
  font-size: 90%;
  font-weight: 400;
}
.upperText {
  text-transform: uppercase;
}

.min-vh-100 {
  min-height: 100vh;
}

.form-group-min-margin {
  margin-bottom: 0.2rem;
}

.quantity-reporting {
  .form-group {
    margin-bottom: 0.5rem;
  }
}

/* Start: Customer logo and powered By Logo */
.login-parent-container {
  display: table;
  height: 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  margin-top: -91px;
}
.login-child-container {
  display: table-cell;
  vertical-align: middle;
}
.maximum-width {
  max-width: 540px;
}
.username-margin-bottom {
  margin-bottom: 0.5rem;
}
.customer-logo {
  width: 350px;
  height: 100px;
  margin: 0 auto;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.login-padding-box {
  padding: 8px 20px;
}
.powered-by-logo {
  margin-right: 15px;
  max-width: 100px;
}

@media only screen and (max-width: 480px) {
  .customer-logo {
    width: 250px;
  }
}

/* End: Customer logo and powered By Logo */

html {
  overflow: hidden;
  position: unset;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  overflow: auto;
  position: initial;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app-version {
  background-color: #1c1a20;
  color: #6c757d;
  text-align: right;
  font-size: 0.6em;
  margin-top: 10px;
  margin-right: 1px;
}
