input[type="file"] {
  visibility: hidden;
  position: absolute;
}

.img-root {
  display: flex;
  flex-wrap: wrap;
}

.padding-box {
  padding: $box-padding;
}

.form-box {
  .dropdown-item {
    white-space: break-spaces;
    color: white;
    &:hover,
    &:active,
    &.active,
    &:focus {
      color: $text-input;
      color: white;
      background: $color1;
    }
  }
  .dropdown-menu {
    width: 100%;
    border-radius: $border-radius-less;
    background: $bg-dark2;
    color: white;
    z-index: 999999;
  }
  .dropdown-toggle {
    border-radius: $border-radius;
    background: $root-bg-dark;
    padding-left: 22px;
    border: 1px solid $border-dark;
    width: 100%;
    text-align: left;
    height: 50px;
    color: $text-white;
    &.selected {
      color: $text-white;
      span{
        max-width: 90%;
        display: block;
        overflow: hidden;
      }
    }
    &:after {
      position: absolute;
      right: 1em;
      top: 50%;
      // border-color: $heading-dark;
      border-color: #FFFFFF;
      border-right-color: transparent;
      border-left-color: transparent;
      transform: translateY(-50%);
    }
  }
  label {
    &.no-margin {
      @media (min-width: 992px) {
        margin-left: 0px;
      }
    }
    font-size: 0.7rem;
    text-transform: uppercase;
    color: $text-white;
    margin-left: 22px;
  }
  .blue-border {
    button {
      border-color: #6fb1e9;
    }
  }
}
.label-heading p {
  font-size: 0.9rem;
  margin-left: 22px;
  margin-bottom: 0px;
}

.form-control {
  @at-root {
    input#{&} {
      height: $input-height;
      border-radius: $border-radius;
    }
    input#{&},
    textarea#{&} {
      border-color: $border-dark;
      background: $root-bg-dark;
      // @include backgroundColorAutoFill($bg-dark3);
      // @include textColorAutoFill($text-input);
      @include placeholder {
        color: $text-white;
      }
      color: $text-white;
      padding-left: 22px;
      &:active,
      &:focus {
        border-color: $border-dark;
        background: darken($root-bg-dark, 2%);
        // color: $text-input;
        color: $text-white;
        box-shadow: 0 0 0 0.2rem rgba(28, 25, 32, 0.31);
      }
    }
    textarea#{&} {
      border-radius: $border-radius-less;
      min-height: 50px;
    }
  }
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 25, 32, 0.31);
  box-shadow: 0 0 0 0.2rem rgba(85, 85, 85, 0.19);
}

h1.logo {
  font-size: 3rem;
  color: white;
}

.btn-primary {
  &.btn-small {
    padding: 0px;
    margin: 0px;
    height: 40px;
    width: 40px;
    min-width: auto;
  }
  &.success {
    background: $green;
    border-color: $green;
    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: lighten($green, 4%);
      border-color: lighten($green, 8%);
    }
  }
  &.fail {
    background: $red;
    border-color: $red;
    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background-color: lighten($red, 4%);
      border-color: lighten($red, 8%);
    }
  }
  background: $color1;
  border-color: lighten($color1, 4%);
  &.btn-one {
    height: $input-height;
    min-width: 140px;
  }
  &.btn-outline {
    background: transparent;
  }
  &.btn-grey {
    background-color: $bg-dark1;
    border-color: $border-dark;
    color: $text-white;
  }
  &:hover {
    background-color: lighten($color1, 4%);
    border-color: lighten($color1, 8%);
  }
  min-width: 120px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: $border-radius;

  margin: 0 auto;
  &.btn-100 {
    width: 100%;
  }
  &:active {
    background: $color1;
  }
}

.btn-primary:not(:disabled):not(.disabled):active {
  background: $color1;
}

.btn-primary.disabled,
.btn-primary:disabled {
  &.btn-outline {
    background: transparent;
  }
  color: #fff;
  background-color: $color1;
  border-color: lighten($color1, 4%);
}
.side-bar {
  @media (min-width: 992px) {
    position: sticky;
    top: 5px;
    bottom: 0;
    min-height: 50vh;
    max-height: 76vh;
    overflow-y: auto;
  }
  @media (min-width: 1440px) {
    height: auto;
    min-height: auto;
  }
}

.btn-secondary {
  color: $heading-dark;
  &:hover {
    color: $heading-dark;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $bg-dark;
}

.padding-reset {
  padding-left: 0px;
  padding-right: 0px;
}

.doc-box {
  i {
    margin-right: 2px;
    border-radius: 50%;
    padding: 5px 10px;
  }
  a {
    display: inline-block;
    background-color: $root-bg-dark;
    padding: 5px 20px 5px 10px;
    border-radius: 22px;
    font-size: 0.7rem;
    margin-left: 12px;
  }
}

.checkboxed {
  input[type="checkbox"] {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    &.disabled,
    &:disabled {
      cursor: not-allowed;
      & + label {
        color: $disabled-color;

        cursor: not-allowed;
        &:before {
          border: 2px solid $disabled-color;
          cursor: not-allowed;
        }
      }
      &:checked + label {
        &:after {
          border-color: $disabled-color;
        }
      }
    }
    & + label {
      position: relative;
      cursor: pointer;
      text-transform: capitalize;
      font-size: 0.8rem;
      user-select: none;
      margin-bottom: 0px;
      &:before {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 2px solid $color1;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 10px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
      }
    }
    &:checked + label {
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        left: 10px;
        width: 4px;
        height: 10px;
        border: solid $color1;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}
.date-input {
  border-radius: $border-radius;
  background: $root-bg-dark;
  padding-left: 22px;
  border: 1px solid $border-dark;
  width: 100%;
  text-align: left;
  height: 50px;
  color: $text-white;
  @media (orientation: landscape) and (orientation: portrait) {
    padding-top: 10px;
  }
  &.selected {
    color: $text-white;
  }
  &::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
  &:before {
    font-family: "FontAwesome";
    content: "\f073";
    position: absolute;
    right: 1em;
    color: #fff;
  }
}

.btn-backward,
.btn-forward {
  min-width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-top: -5px;
  padding: 0px;
  margin-bottom: 5px;
}
.btn-backward {
  margin-right: 20px;
}
.btn-forward {
  margin-left: 20px;
}
.error-msg {
  display: block;
  padding: 10px;
  color: #af2727;
  font-weight: bold;
}

.user-name {
  margin-bottom: 10px;
  margin-left: 15px;
  h5 {
    color: #fff;
  }
}
.map-integration-note {
  font-size: 14px;
  color: #6fb1e9;
  line-height: 16px;
  margin: 5px 12px;
  display: block;
  text-align: justify;
}
