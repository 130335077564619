$root-bg-dark: #1c1a20;
$bg-dark: #29272d;
$bg-dark1: #363439;
$bg-dark2: #262329;
$bg-dark3: #48484a;
$border-dark: #323136;
$input-bg-dark: #455262;
$input-border: rgba(69, 82, 98, 0.3);
$input-border--hover: rgba(69, 82, 98, 0.9);

$text-input: #e8eef5;
$text-label: darken(#e8eef5, 6%);
$disabled-color: #7a7979;
$disabled-color--light: #e2e2e2;

$color1: #07b9ff;
$green: #a1dc0b;
$red: #ff0529;
$text-dark: #d2d2d2; /*#86868f;*/
$text-dark1: #d2d2d2;
$text-white: #ffffff;
$heading-dark: #c3c3c8;
$shadow: 0 0px 5px 5px rgba(24, 34, 46, 0.17);

.icon-red {
  color: #f35a5a;
}

.icon-orange {
  color: #fead05;
}

.icon-green {
  color: #529b26;
}

.icon-blue {
  color: #216faa;
}

.icon-grey {
  color: #666666;
}
