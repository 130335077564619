.slider {
  position: relative;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  height: auto;
  .slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;

    .slide {
      display: inline-block;
      height: 100%;
      width: 100%;
      height: 500px;
      vertical-align: top;
      height: auto;
    }
  }
}

.arrow {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: $bg-dark1;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  cursor: pointer;
  transition: transform ease-in 0.1s;
}

.nextArrow {
  position: absolute;
  top: 150px;
  right: 9%;
  @media (max-width: 991px) {
    // right: 0px;
  }
  z-index: 999;
  color: #fff;
}

.backArrow {
  position: absolute;
  top: 150px;
  left: 9%;
  @media (max-width: 991px) {
    // left: 0px;
  }
  z-index: 999;
  color: #fff;
}

.fa-arrow-right,
.fa-arrow-left {
  &.inactive {
    &:before {
      color: $bg-dark3;
    }
  }
  &:before {
    color: $color1;
  }
}

.img-upload-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  height: 500px;
  overflow: hidden;
  // background: $bg-dark1;
  color: $heading-dark;
  border-radius: 15px;
  .slider-buttons {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 60px;
    position: absolute;
    z-index: 9999;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    bottom: 0px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    transition: 200ms all ease-in-out;
  }
  &:hover{
    .slider-buttons, .img-num {
      background: rgba(0, 0, 0, 0.5);
    }

  }

  .img-num {
    position: absolute;
    width: 32px;
    background: rgba(0, 0, 0, 0.3);
    top: 0px;
    left: 0px;
    height: 32px;
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 300px;
    max-width: 300px;
    margin: 0 auto;
    background-color: $bg-dark1;
    border: 1px solid $root-bg-dark;
    border-radius: $border-radius-less;
    position: relative;
    p {
      // font-weight: bold;
      color: $heading-dark;
    }
  }
  .btn-label,
  .btn-card {
    max-width: 400px;
    transition: 100ms all ease-in;
    border: 1px solid $color1;
    color: white !important;
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-left: 0px !important;
    cursor: pointer;
    border-radius: 40px;
    margin-top: 1rem;
    width: 100%;
    background: none;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: normal;
    margin: 0;
    margin: 10px;
    width: 40px;
    &:hover {
      background-color: lighten($color1, 4%);
    }
  }
}
.image-group {
  border-bottom: 1px solid $root-bg-dark;
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-align: center;
  .slider {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  p {
    word-break: break-all;
  }
  h5 {
    font-size: 1rem;
    margin: 0;
  }
  .group-desc {
    font-size: 0.8rem;
    margin: 0;
  }
}

.slider-width{
  width:300px;
  margin: 0 auto;
}
.mix-bag{
  position: relative;
  width: 300px;
  margin: 0 auto;
  textarea {
    height: 50px;
    min-height: 50px;
    max-height: 100px;
    width: 300px;
    margin: 0 auto;
    margin-top: 4px;
    border-radius: 25px;
    padding-right: 50px;
    font-size: 0.7rem;
  }
  button{
    position: absolute;
    top: 28px;
    transform: translateY(-50%);
    right: 4px;
  }
}