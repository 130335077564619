.modal-canvas {
  z-index: 9999;
  max-width: 1000;
  max-height: 750;
  .btn {
    padding: 0.175rem 0.4rem !important;
  }
  .btn-info {
    border: 1px solid #07b9ff !important;
    background-color: #29272d !important;
    text-align: center !important;
    cursor: pointer !important;
  }
  .btn-info.active {
    background-color: #07b9ff !important;
  }
  .btn.focus,
  .btn:focus,
  .btn-info.focus,
  .btn-info:focus {
    box-shadow: none !important;
  }
  .modal-content {
    background-color: #29272d;
    border-color: #323136;
    border-radius: 15px;
  }
  @media (min-width: 576px) {
    .modal-dialog {
      margin: 0.5rem auto !important;
    }
  }
}
