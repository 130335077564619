.z-index-top {
  z-index: 99999 !important;
}

.z-index-2 {
  z-index: 2;
}

.row-reset {
  margin-right: 0px;
  margin-left: 0px;
}

.flex {
  display: flex;
  align-items: center;
  // justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  margin: 0 auto;
  border: 4px solid $root-bg-dark;
  border-top: 4px solid $color1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.inline-child > * {
  display: inline-block;
}

.label-button {
  font-size: 0.6rem !important;
  margin: 0px;
  margin-right: 10px;
}
.overflow-x {
  overflow-x: scroll;
}

.font-box {
  text-align: center;
  padding: 10px 0px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
  margin-bottom: 27px;
  border-bottom: 1px solid rgb(30, 29, 31);
}

.width-100 {
  width: 100%;
}
.Toastify__toast {
  border-radius: 15px;
}

.m-auto {
  margin: 0 auto !important;
}
@media (max-width: 991px) {
  .m-padding-reset {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 40em) {
  .m-no-padding-lr {
    padding-left: 0px;
    padding-right: 0px;
  }
  .m-even-padding {
    padding: 10px;
    &.top-margin-1rem {
      margin-top: 1rem !important;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.display-none {
  display: none;
}
