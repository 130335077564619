table,
td,
tr {
  // color: #c5c4cc;
  color: $text-white;
}

.table th {
  border-top: none;
  padding: 1rem 1.2rem;
}

.table td {
  border-top: none;
  border-bottom: 1px solid #1c1a20;
  vertical-align: middle;
  @media (max-width: 991px) {
  }
  @media (min-width: 992px) {
    height: 70px;
    padding: 1rem 1.2rem;

    &.td-min-width {
      min-width: 180px;
    }
  }
}

.table {
  thead {
    th {
      font-weight: normal;
      border-bottom: 1px solid $border-dark;
    }
  }
  tbody {
    tr {
      transition: 200ms all ease-in-out;
      &:hover {
        background-color: lighten($bg-dark, 2%);
      }
    }
  }
}
.table-without-bottom-margin {
  margin-bottom: 0;
}
.table-theme-1 {
  thead {
    tr {
      th {
        &.heading-1 {
          padding: 0px 5px 10px 5px;
        }
        &.heading-2 {
          padding: 10px 5px;
          font-weight: bold;
        }
        &.align-right {
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      &.row-highlight {
        background-color: #2e2c32;
      }
      td {
        &.row-theme-1 {
          padding: 10px 5px;
        }
        &.row-theme-2 {
          padding: 5px 5px;
        }
        &.align-right {
          text-align: right;
        }
        &.text-center {
          text-align: center;
        }
        .fa-trash:before {
          content: "\f1f8";
        }
        footer {
          // height: 100px;
          // left: -4px;
          position: relative;
          top: -21px;
          height: 0px;
          // -webkit-transform: rotate(-45deg);
          font-style: oblique;
          // color: #9e9ea0;
          color: #ff061d;
        }
      }
    }
  }
}

.table-theme-2 {
  font-size: 14px;
  thead {
    tr {
      th {
        padding: 5px 5px;
        &.heading-1 {
          font-weight: bold;
          padding: 0px 5px 1px 5px;
        }
        &.heading-2 {
          background-color: lighten($root-bg-dark, 2%);
          font-weight: normal;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 5px 5px;
        &.row-theme-1 {
          padding: 9px 5px 1px 5px;
        }
        &.row-theme-2 {
          background-color: $root-bg-dark;
          padding: 2px 5px;
          font-size: 13px;
          font-weight: normal;
        }
        &.row-theme-3 {
          background-color: lighten($root-bg-dark, 2%);
        }
        &.row-empty {
          padding: 5px 0px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .mtm-5 {
    margin-top: 3rem;
  }
}
// @media (min-width: 40.1em) {
//   .responsiveTable.table td {
//     // width:160px;
//   }
// }

@media (max-width: 40em) {
  .responsiveTable.table tbody tr {
    .btn-primary.btn-small {
      width: 100%;
      height: 24px;
      font-size: 0.8rem;
    }
    .responsiveTable td.pivoted {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    border-color: transparent;
    border-bottom: 2px solid $root-bg-dark;
    // padding-bottom: 1em;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
}
