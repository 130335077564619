.navbar {
  display: -webkit-inline-box;
  padding: 0px;
  margin: 0px;
  background-color: $bg-dark !important;
  width: 100%;
  border-bottom: 1px solid $border-dark;
  .navbar-container {
    width: 100%;
    margin: 0 0 0 13px;
  }
  .navbar-logo {
    margin: 15px 0;
    .logo {
      height: 25px !important;
    }
  }
  .navbar-project {
    margin: 15px 0;
    margin-right: 5px;
    .btn {
      padding: 0px 5px;
      font-size: 17px;
      color: #fff;
      font-weight: 600;
    }
  }
  .navbar-account,
  .navbar-module-menu {
    margin: 15px 0;
    .btn {
      padding: 0px 5px;
      font-size: 17px;
      color: #fff;
      font-weight: 600;
    }
    .dropdown-custome-item {
      padding: 0.25rem 1.5rem;
      color: #fff;
    }
  }
  .navbar-module-name {
    font-size: 17px;
    color: #fff;
    font-weight: 600;
  }
  .navbar-account {
    margin-right: 15px;
  }
  .navbar-module-menu {
    margin: 11px 0;
    margin-right: 10px;
  }
  .navbar-module-menu .dropdown .btn {
    background-color: #5eb9f9 !important;
    border-radius: 3px;
    padding: 5px;
    &:focus {
      box-shadow: none;
    }
  }
  .navbar-module-menu .dropdown .dropdown-toggle {
    border-color: #5eb9f9;
  }
  .navbar-seprator {
    border-left: 1px solid #323136;
  }

  @media (max-width: 40em) {
    .navbar-project,
    .navbar-account,
    .navbar-account,
    .navbar-module-menu {
      .btn {
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }
    }
    .navbar-project,
    .navbar-account,
    .navbar-account {
      .btn {
        width: 130px;
      }
    }
  }

  &.navbar-none {
    background: none;
    position: absolute;
    padding: 0px;
    width: auto;
    box-shadow: none;
    border: none;
    top: 2%;
    left: 4%;
  }

  .btn-secondary {
    background: transparent;
    border-color: transparent;
  }
  .show > .btn-secondary.dropdown-toggle {
    background: transparent;
    box-shadow: none;
  }
  .z-index-top.dropdown-menu.show {
    background: $bg-dark2;
    .dropdown-item {
      color: white;
      &:hover,
      &:active,
      &.active,
      &:focus {
        color: $text-input;
        color: white;
        background: $color1;
      }
    }
  }
  .navbar-nav {
    .nav-link {
      color: white;
    }
  }
}
