/* global mxins */
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content};
}

@mixin backgroundColorAutoFill($backgroundColor) {
  -webkit-box-shadow: 0 0 0px 9999px $backgroundColor inset;
}

@mixin textColorAutoFill($color) {
	-webkit-text-fill-color: $color !important;
}

