.menu-padding-box {
  padding: 13px;
}
.btn-block + .btn-block {
  margin-top: 13px;
}
button.menubutton {
  color: #fff;

  /* staff check-in */
  &.theme-1 {
    background-color: #79bf6a;
    border-color: #79bf6a;
    &:hover,
    &:active,
    &:focus {
      background-color: #5a9b48;
      border-color: #5a9b48;
    }
  }
  /* fiber instalation plan || reporting of quantities */
  &.theme-2 {
    background-color: #50b8f9;
    border-color: #50b8f9;
    &:hover,
    &:active,
    &:focus {
      background-color: #2379e2;
      border-color: #2379e2;
    }
  }
  /* time reporting*/
  &.theme-3 {
    background-color: #7e8488;
    border-color: #7e8488;
    &:hover,
    &:active,
    &:focus {
      background-color: #5a5b5b;
      border-color: #5a5b5b;
    }
  }
  /* deshboard */
  &.theme-4 {
    background-color: #004683;
    border-color: #004683;
    &:hover,
    &:active,
    &:focus {
      background-color: #043756;
      border-color: #043756;
    }
  }
}
