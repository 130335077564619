.m-tabs {
  border: none;
  display: flex;
  justify-content: center;
  // border-bottom: 1px solid $border-dark;
  border-bottom: 1px solid transparent;
  box-shadow: 0px 3px 0px 0px $root-bg-dark;
  @media (min-width: 992px) {
    span {
      margin-right: 10px;
    }
  }

  @media (max-width: 991px) {
    p {
      display: none;
    }
  }
  p {
    margin: 0px;
    display: inline;
    padding: 0px;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .nav-item {
    flex: 1;
    border-radius: 0px;
    @media (max-width: 991px) {
      flex: 1;
    }

    &:hover {
      .nav-link {
        border-bottom: 1px solid $color1;
        color: $color1;
        cursor: pointer;
      }
    }
    .nav-link {
      background: transparent;
      transition: 100ms background-color ease-in;
      border: 1px solid transparent;
      transition: 200ms all cubic-bezier(0, 0, 0.2, 1);
      padding: 20px 0px;
      text-align: center;
      @media (max-width: 991px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.active {
        border-bottom: 1px solid $color1;
        color: $color1;
      }
    }
  }
}
.tab-content {
  padding: $box-padding;
  @media(max-width: 40em){
    padding: 12px 6px;
    h1{
      text-align: center;
    }
  }
}

.pill-tabs {
  border: none;
  display: flex;
  justify-content: center;
  border-bottom: none;
  flex: 1;
  .nav-item {
    border: 1px solid $color1;
    min-width: 150px;
    text-align: center;
    &.active {
      background: $color1;
      color: white;
    }
    cursor: pointer;
    &.nav-link {
      cursor: pointer;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: 1px solid transparent;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: 1px solid transparent;
    }
  }
}
